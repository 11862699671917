:root {
  // https://core.telegram.org/bots/webapps#themeparams
  --bg-color: var(--tg-theme-bg-color, #fff);
  --text-color: var(--tg-theme-text-color, #000);
  --hint-color: var(--tg-theme-hint-color, #999);
  --link-color: var(--tg-theme-link-color, #3b8fbb);
  --button-color: var(--tg-theme-button-color, #3b8fbb);
  --button-text-color: var(--tg-theme-button-text-color, #fff);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color, #efeff3);
  --viewport-height: var(--tg-viewport-height);
}

html {
  body {
    background-color: var(--bg-color);
    color: var(--text-color);
  }

  a {
    color: var(--link-color);
  }

  button {
    color: var(--button-text-color);
    background-color: var(--button-color);
  }
}
