@import "telegram";

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  user-select: none;
}

#container {
  width: 100%;
  max-width: 480px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

#pages {
  display: flex;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  margin-right: 0px;
  transform-origin: center center;
  transform: scale(1);
}
