:root {
  --bg-color: var(--tg-theme-bg-color, #fff);
  --text-color: var(--tg-theme-text-color, #000);
  --hint-color: var(--tg-theme-hint-color, #999);
  --link-color: var(--tg-theme-link-color, #3b8fbb);
  --button-color: var(--tg-theme-button-color, #3b8fbb);
  --button-text-color: var(--tg-theme-button-text-color, #fff);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color, #efeff3);
  --viewport-height: var(--tg-viewport-height);
}

html body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

html a {
  color: var(--link-color);
}

html button {
  color: var(--button-text-color);
  background-color: var(--button-color);
}

body {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

#container {
  width: 100%;
  max-width: 480px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#pages {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.page {
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  transform-origin: center;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  display: flex;
  transform: scale(1);
}

/*# sourceMappingURL=index.04db71c2.css.map */
